

.MuiContainer-root {
    margin-top: 42px;
}

.container-login-page {
    display: flex;
    // height: 100vh;
    min-width: 260px;
    .wrapper-form {
        margin: auto;
        width: 40%;
        background: rebeccapurple;
    }
}

.buttons-wrapper {
    margin: 24px 0 0 0;
}
    


@media screen and (max-width: 1224px) {

    .container-login-page {
        display: flex;
        .wrapper-form {
            width: 50%;
        }
    }

}


/* iPads (landscape) ----------- */
@media only screen and (max-width : 768px) {
    .container-login-page {
        display: flex;
        .wrapper-form {
            width: 60%;
        }
    }

    .buttons-wrapper {
        margin: 24px 0 0 0;
    }
}


/* iPhone 6, 7, 8 ----------- */
@media only screen and (max-width: 475px) {
    .container-login-page {
        display: flex;
        .wrapper-form {
            width: 100%;
        }
    }

}